import React, {
  Suspense,
  createContext,
  lazy,
  useCallback,
  useContext,
  useState,
} from 'react';
import { ResponsiveDialog } from 'ui/components';
import ResponsiveDialogProvider from 'ui/components/ResponsiveDialog/responsive-dialog-provider';

const MODAL_COMPONENTS = {
  email_settings: lazy(
    () => import('../components/inbox-v2/InboxSettingsModal')
  ),
  export_tasks: lazy(() => import('../components/ExportTasksModal')),
  pull_monday: lazy(
    () => import('../components/integrations/pull/MondayModal')
  ),
  pull_trello: lazy(
    () => import('../components/integrations/pull/TrelloModal')
  ),
  pull_basecamp: lazy(
    () => import('../components/integrations/pull/BaseCampModal')
  ),
  pull_teamwork: lazy(
    () => import('../components/integrations/pull/TeamworkModal')
  ),
  pull_jira: lazy(() => import('../components/integrations/pull/JiraModal')),
  pull_asana: lazy(() => import('../components/integrations/pull/AsanaModal')),
  pull_clickup: lazy(
    () => import('../components/integrations/pull/ClickUpModal')
  ),
  log_time: lazy(() => import('../components/LogActiveTimeModal')),
  start_wizard: lazy(() => import('../components/plans/StartWizardModal')),
  task_create: lazy(() => import('../components/modals/TaskCreateModal')),
  project_settings: lazy(() => import('../components/project-v2/ProjectSingleSettingModal')),
  create_folder: lazy(() => import('../components/project-v2/CreateFolderModal')),
  delete_project: lazy(() => import('../components/project-v2/DeleteProjectModal')),
  archive_project: lazy(() => import('../components/project-v2/ArchiveProjectModal')),
  // Add other modals here as needed
} as const;

type ModalName = keyof typeof MODAL_COMPONENTS;

export interface GlobalModalProps {
  onClose: (props?: any) => Promise<void> | void;
  isOpen?: boolean;
}

type ModalProps = {
  [K in ModalName]: Omit<
    React.ComponentProps<(typeof MODAL_COMPONENTS)[K]>,
    'onClose'
  >;
};

interface ModalConfig<T extends ModalName> {
  props?: ModalProps[T];
  onClose?: (props?: any) => Promise<void> | void;
}

interface ModalContextType {
  openModal: <T extends ModalName>(
    name: T,
    config?: ModalConfig<T>
  ) => void;
  closeModal: (name: ModalName, result?: any) => void;
  isOpen: (name: ModalName) => boolean;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

interface ModalProviderProps {
  children: React.ReactNode;
}

export const ModalProvider: React.FC<ModalProviderProps> = ({
  children,
}) => {
  const [openModals, setOpenModals] = useState<
    Partial<
      Record<ModalName, ModalConfig<ModalName>>
    >
  >({});

  const openModal = useCallback(
    <T extends ModalName>(name: T, config?: ModalConfig<T>) => {
      setOpenModals((prev) => ({ ...prev, [name]: config }));
    },
    []
  );

  const closeModal = useCallback(
    async (name: ModalName, result?: any) => {
      const modalConfig = openModals[name];
      if (modalConfig?.onClose) {
        await modalConfig.onClose(result);
      }
      setOpenModals((prev) => {
        const newModals = { ...prev };
        delete newModals[name];
        return newModals;
      });
    },
    [openModals]
  );

  const isOpen = useCallback(
    (name: ModalName) => !!openModals[name],
    [openModals]
  );

  return (
    <ModalContext.Provider value={{ openModal, closeModal, isOpen }}>
      <ResponsiveDialogProvider>
        {children}
        {(
          Object.entries(openModals) as [
            ModalName,
            ModalConfig<ModalName>,
          ][]
        ).map(([name, config]) => {
          const Modal = MODAL_COMPONENTS[name];
          return Modal ? (
            <ResponsiveDialog
              key={name}
              open={true}
              onOpenChange={() => closeModal(name)}
            >
              <Suspense fallback={<div>Loading...</div>}>
                <Modal
                  {...config?.props}
                  isOpen={true}
                  onClose={(result?: any) => closeModal(name, result)}
                />
              </Suspense>
            </ResponsiveDialog>
          ) : null;
        })}
      </ResponsiveDialogProvider>
    </ModalContext.Provider>
  );
};

// Custom hook to use the modal context
export const useModal = <T extends ModalName>(name: T) => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider');
  }

  return {
    open: (config?: ModalConfig<T>) => context.openModal(name, config),
    close: (result?: any) => context.closeModal(name, result),
    isOpen: () => context.isOpen(name),
  };
};
