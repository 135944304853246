import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ProjectState {
  websiteIds: number[];
  encryptedWebsiteIds: string[];
  isBulkSelecting: boolean;
}

const initialState: ProjectState = {
  websiteIds: [],
  encryptedWebsiteIds: [],
  isBulkSelecting: false,
};

const updateBulkSelecting = (state: ProjectState) => {
  state.isBulkSelecting =
    state.websiteIds.length > 0 || state.encryptedWebsiteIds.length > 0;
};

const projectSlice = createSlice({
  name: 'project_new',
  initialState,
  reducers: {
    setWebsiteIds: (state, action: PayloadAction<number[]>) => {
      state.websiteIds = action.payload;
      updateBulkSelecting(state);
    },
    setEncryptedWebsiteIds: (state, action: PayloadAction<string[]>) => {
      state.encryptedWebsiteIds = action.payload;
      updateBulkSelecting(state);
    },
    clearWebsiteIds: (state) => {
      state.websiteIds = [];
      state.encryptedWebsiteIds = [];
      state.isBulkSelecting = false;
    },
    addWebsiteId: (state, action: PayloadAction<number>) => {
      state.websiteIds.push(action.payload);
      state.isBulkSelecting = true;
    },
    removeWebsiteId: (state, action: PayloadAction<number>) => {
      state.websiteIds = state.websiteIds.filter((id) => id !== action.payload);
      updateBulkSelecting(state);
    },
    addEncryptedWebsiteId: (state, action: PayloadAction<string>) => {
      state.encryptedWebsiteIds.push(action.payload);
      state.isBulkSelecting = true;
    },
    removeEncryptedWebsiteId: (state, action: PayloadAction<string>) => {
      state.encryptedWebsiteIds = state.encryptedWebsiteIds.filter(
        (id) => id !== action.payload
      );
      updateBulkSelecting(state);
    },
  },
  extraReducers: (builder) => {},
});

export const {
  setWebsiteIds,
  setEncryptedWebsiteIds,
  clearWebsiteIds,
  addWebsiteId,
  removeWebsiteId,
  addEncryptedWebsiteId,
  removeEncryptedWebsiteId,
} = projectSlice.actions;

export default projectSlice.reducer;
