import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { maintenancePollingInterval } from 'utils/constants';
import { hideMaintenancePage, showMaintenancePage } from '../slices/global';

const baseUrl = import.meta.env.VITE_APP_BASE_URL;

const customFetchBase = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers) => {
    headers.set('Accept', 'application/json');
    return headers;
  },
  validateStatus: (response, result) => {
    // Check if response status is in the 200-299 range
    if (response.status >= 200 && response.status <= 299) {
      // If the result has a status field and it's false, consider it an error
      if (
        result &&
        typeof result.status === 'boolean' &&
        result.status === false
      ) {
        return false;
      }
      return true;
    }
    // Consider all other status codes as errors
    return false;
  },
  credentials: 'include',
  timeout: 60000,
});

const baseQueryWithMaintenanceCheck: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await customFetchBase(args, api, extraOptions);
  if (result.error && result.error.status === 503) {
    // Set maintenance mode
    api.dispatch(showMaintenancePage());

    // Start polling
    while (true) {
      await new Promise((resolve) =>
        setTimeout(resolve, maintenancePollingInterval)
      );
      try {
        const response = await fetch(`${baseUrl}/up`);
        if (response.status === 200) {
          // Service is back up
          api.dispatch(hideMaintenancePage());
          // Retry the original query
          result = await customFetchBase(args, api, extraOptions);
          break;
        }
      } catch (error) {
        // keep polling baby! :)
      }
    }
  }
  return result;
};

export const baseApi = createApi({
  baseQuery: baseQueryWithMaintenanceCheck,
  tagTypes: [
    'Site',
    'SiteSetting',
    'Contributor',
    'Project',
    'Task',
    'Attribute',
    'Filter',
    'User',
    'Setting',
    'Snippet',
    'Page',
    'TimeEntry',
    'NotLoggedEntry',
    'Trial',
  ],
  endpoints: () => ({}),
});
