import { useFeatureFlagEnabled } from 'posthog-js/react';

interface FeatureFlagWrapperProps {
  flagName: string;
  featureOn: React.ReactNode;
  featureOff: React.ReactNode;
}

const FeatureFlagWrapper = ({
  flagName,
  featureOn,
  featureOff,
}: FeatureFlagWrapperProps) => {
  const isEnabled = useFeatureFlagEnabled(flagName);

  if (isEnabled === undefined) return null;
  return isEnabled ? featureOn : featureOff;
};

export default FeatureFlagWrapper;
